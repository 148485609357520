<template>
    <div class="container my-24 my-lg-40">
      <div class="row">
        <div class="col-12 col-xl-3 order-xl-2 mb-24 sticky">
          <v-card class="overflow-hidden sticky">
            <l-widget>
              <template #header>
                <w-timer
                  :start="widget.timer.start"
                  :end="widget.timer.end"
                  :server-time="widget.timer.current"
                />
              </template>

              <template #default>
                <w-progress
                  :progress="widget.progress"
                />
              </template>
            </l-widget>
          </v-card>
        </div>

        <div class="col-12 col-xl-9 order-xl-1">
          <v-card class="py-32 px-16 p-xl-32">
            <div class="row mb-24">
              <div class="col">
                <img
                  class="question-img"
                  :src="require(`@/assets/images/verstka/diagram-1.png`)"
                  alt=""
                >
              </div>
            </div>

            <p class="mb-32">
              Найдите последовательность, в которой расположены фигуры.
              Выберите фигуру из списка ниже, которая продолжает
              данную последовательность.
            </p>

            <div class="row">
              <div
                v-for="img in answers.img"
                :key="img"
                class="col-auto answer-img"
              >
                <v-card-button>
                  <img
                    :src="require(`@/assets/images/verstka/${img}`)"
                    alt=""
                    class="answer-img__img"
                  >
                </v-card-button>
              </div>
            </div>

            <v-button
              @click="done"
              class="mt-40"
            >
              Ответить
            </v-button>
          </v-card>
        </div>
      </div>
    </div>
</template>

<script>
import {
  VButton,
  VCard,
  VCardButton
} from '@components/base'
import { WTimer, WProgress } from '@components/widgets'
import { LWidget } from '@/layouts'

export default {
  name: 'SRadioPictures',

  components: {
    LWidget,
    VButton,
    VCard,
    VCardButton,
    WProgress,
    WTimer
  },

  data () {
    return {
      widget: {
        progress: {
          total: 3,
          current: 1
        },
        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 120
        }
      },
      answers: {
        img: [
          'diagram-1.png',
          'diagram-2.png',
          'diagram-3.png'
        ]
      }
    }
  },

  methods: {
    done () {
      console.log('Ответил')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@assets/styles/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.question-img {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.answer-img {
  //padding-right: 1.5rem;
  margin-bottom: 1rem;

  &:not(:first-child) {
    padding-left: .2rem;
  }
  &:not(:last-child) {
    padding-right: .2rem;
  }

  @include media-breakpoint-down(sm) {
    //padding-right: .2rem;
  }

  &__img {
    display: block;
    @include media-breakpoint-down(sm) {
      width: 2.5rem;
    }
  }
}
</style>
